

.messenger {
    height: calc(100vh - 100px);
    display: flex;
    margin: 0 20px;
    background: #fefefe;
}

.chatMenu {
    flex: 3;
}

.chatMenuInput {
    width: 80%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid gray;
}

.chatBox {
    flex: 6;
}

.chatBoxWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.chatBoxTop {
    height: 100%;
    overflow-y: scroll;
    padding-right: 14px;
    margin-bottom: 14px;
}

.chatBoxBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 20px; */
}

.chatMessageInput {
    width: 90%;
    height: 40px;
    padding: 10px;
    border: 1px solid rgb(197, 193, 193);
    outline: none;
}

.chatSubmitButton {
    width: 80px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    border: none;
    border-radius: 0px 6px 6px 0px;
    cursor: pointer;
    background-color: #1877f2;
    color: white;
}

.chatSubmitButton:hover {
    background-color: #114b97;
}

.chatMenuWrapper, .chatBoxWrapper, .chatOnlineWrapper {
    padding: 10px;
    height: 100%;
    border-radius: 6px;
    margin: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.noCoversationText {
    position: absolute;
    top: 10%;
    font-size: 28px;
    color: rgb(224, 220, 220);
    cursor: default;
}

.chatBoxTypingText {
    color: gray;
    margin-bottom: 4px;
}