* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

  .section {
    padding: 54px 0 0 0;
    background-color: #fff;
  }
  
  .search-box {
    width: 40vw; 
  }

  /* // rc-time-picker additional css */
  .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  .rc-time-picker-clear,
  .rc-time-picker-clear-icon:after {
    font-size: 15px;
  }

  .rc-time-picker-panel-select,
  .rc-time-picker-input,
  .rc-time-picker-panel-input {
    font-family: "Consolas", sans-serif;
    font-size: 16px;
    cursor: pointer;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }


  @media screen and (max-width: 786px) {
    .search-box {
      width: 86vw; 
    }
  }
  
