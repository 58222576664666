video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    background-color: #000;
  }

.transition {
  transition-timing-function: ease-in-out;
}
  
  .hero-container {
    /* background: url('/images/cover-img.jpg') center center/cover no-repeat; */
    height: 660px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(8, 22, 39, 0.2);
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 70px;
    margin-top: 320px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 60px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .hero-container > h1 {
      font-size: 36px;
    }
  
    .hero-container > p {
      font-size: 24px;
    }
  }