.footer-container {
  /* background-color: #242424; */
  /* background: #081627; */
  /* background: #101820FF; */
  /* background: #081627; */
  background: #11101D;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
  /* font-size: 2rem; */
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
  /* font-size: 1.6rem; */
}

.btn {
  width: max-content;
  padding: 10px 20px;
  font-size: large;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  /* font-size: 24px; */
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  margin-left: 100px;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0px;
  text-align: left;
  width: 320px;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

.website-rights {
  color: #fff;
  margin: 60px 0 0 0;
  font-size: 1.08rem;
}

@media screen and (max-width: 960px) {
  .footer-links {
    padding-top: 2rem;
    flex-direction: column;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .footer-link-wrapper {
    flex-direction: column;
  }

  .footer-links {
    margin-left: 0px;
  }

}


